.overflow-hidden {
  overflow: hidden !important;
}
.orders_filter {
  position: sticky;
  top: 8%;
  padding: 0px 10%;
  border-bottom: 1px solid rgba(128, 128, 128, 0.178);
  background-color: white !important;
}
.orders_parent {
  width: 100% !important;
  height: 100vh;
  padding-top: 8rem;
}
.live_menu {
  width: 50%;
  text-align: center;
  padding: 10px;
  margin: 0px 10%;
  margin-bottom: 0.5px;
  cursor: pointer;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.activeClass {
  border-bottom: 3px solid pink;
}
.past_menu {
  width: 50%;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  margin-right: 10%;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}


.orders_data_div .spinner {
  margin-left: 50%;
}

.order_card {
  width: 70%;
  margin: 20px auto;
  height: fit-content;
  border: 1px solid rgba(128, 128, 128, 0.281);
  border-radius: 8px;
  background-color: white;
  display: table;
}
.order_card:hover {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.089),
    0px 0px 25px rgba(0, 0, 0, 0.089);
}
.activeClass {
  border-bottom: 3px solid pink;
}
.past_menu {
  width: 50%;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  margin-right: 10%;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  /* border-bottom: 2px solid pink; */
}
.orders_data_div {
  max-height: 75vh;
  width: 100%;
  background-color: #f5f5f5;
  padding: 10px;
  overflow-y: scroll;
}

.order_card {
  width: 70%;
  margin: 20px auto;
  height: fit-content;
  border: 1px solid rgba(128, 128, 128, 0.281);
  border-radius: 8px;
  background-color: white;
  display: table;
}
.order_card:hover {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.089),
    0px 0px 25px rgba(0, 0, 0, 0.089);
}
.card_brdr {
  border: 1px solid rgba(128, 128, 128, 0.281);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.brdr2 {
  border: 1px solid #f5f5f5;
}
.order_card_header {
  padding: 10px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.123);
  z-index: 10 !important;
}
.order_header_right {
  line-height: 10px;
  font-size: 14px;
}
.order_card_body {
  padding: 10px;
  max-height: fit-content;
  border-bottom: 1px solid rgba(128, 128, 128, 0.123);
  align-items: center;
}

.order_card_accordian {
  width: 100%;
  border: none !important;
  z-index: 2 !important;
}

.order_card_accordian > .accordion-button {
  color: #5bd2cc !important ;
}
.order_card_accordian > .accordion-item {
  border: none !important;
}
.line_height_10 {
  line-height: 15px;
}
.order_meds {
  font-size: 14px;
  margin: 10px;
  background-color: #ebfafa;
  padding: 10px;
}
.order_meds:hover {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.062), 0px 0px 15px rgba(0, 0, 0, 0.055);
}
.order_meds_edit {
  font-size: 14px;
  margin: 10px;
  background-color: #f0f0f048;
  border: 1px solid rgba(128, 128, 128, 0.24);
  border-radius: 5px;
  padding: 10px;
}
.amount_map_sec {
  padding: 5px;
  margin: 0px 20px;
}
.higlight_blue {
  background-color: #48d1cc !important;
  color: #4b4495 !important;
  padding: 3px;
  text-align: center;
  border-radius: 4px;
  margin: 5px;
  font-size: 12px;
}
.order_card_total_sec {
  border-top: 1px solid rgba(128, 128, 128, 0.144);
  border-bottom: 1px solid rgba(128, 128, 128, 0.144);
  margin: 5px;
  padding: 10px;
}
.higlight {
  background-color: #ffd500d2 !important;
  color: #7e6322 !important;
  padding: 3px;
  text-align: center;
  border-radius: 4px;
  margin: 5px;
  font-size: 12px;
}
.order_edit_btn {
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
}

.empty_list_sec {
  margin: 10% auto !important;
  text-align: center !important;
}
.empty_list_sec > img {
  width: 200px;
  margin-bottom: 20px;
}

.cancel_btn_order {
  padding: 5px;
  width: 150px;
  text-align: center;
}
.save_btn {
  padding: 5px;
  width: 150px;
  border: 1px solid #4b4495 !important;
  color: #4b4495;
  font-weight: bold;
  text-align: center;
}
.save_btn:hover {
  background-color: #4b4495 !important ;
  color: white;
}
.orders_c2a_cntr {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.orders_c2a_cntr button {
  padding: 5px;
  width: 100px;
  border-radius: 2px;
  font-size: 0.9rem;
  box-shadow: 0 1px 2px 0 #00000033;
  outline: none;
}
.payNow_btn {
  border: 1px solid #4b4495 !important;
  background-color: #4b4495 !important ;
  color: white;
}
.payNow_btn:hover {
  background-color: white !important ;
  color: #4b4495;
}
.return_btn {
  border: 1px solid #4b4495 !important;
  color: #4b4495;
}
.return_btn:hover {
  background-color: #4b4495 !important ;
  color: white;
}
.edit_btn_return {
  padding: 5px;
  width: 80px;
  height: 40px;
  border: 1px solid #4b4495 !important;
  color: #4b4495;
  font-weight: bold;
  margin-top: 4px;
}
.edit_btn_return:hover {
  background-color: #4b4495 !important ;
  color: white;
}
.return_item_select {
  font-size: 18px;
  margin-right: 10px;
}
.reasonInput {
  padding: 10px;
}

.continue_btn {
  width: 30%;
}
.reason_highlight {
  background-color: #ffdec6 !important;
}
.return_stepper_sec {
  background-color: #f8fafc;
  width: 95%;
  margin-top: 10px;
  overflow: hidden;
}
.stepper {
  padding: 3px;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  border: 1px solid #4b4495;
}
.v_line {
  border-top: 1px solid #4b4495;
  width: 180px;
  height: 2px;
  margin-top: 3px !important;
}
.stepper_cont {
  margin-right: 50px;
}
.return_footer {
  padding: 10px;
  margin: 5px 20px;
}
.return_items {
  margin: 10px 10%;
}
.edit {
  background-color: rgba(245, 245, 245, 0.623) !important;
  border: 1px solid rgba(128, 128, 128, 0.342);
  border-radius: 4px;
}
.return_del {
  width: 15px;
  margin-top: -5px !important;
}
.return_date {
  background-color: #00000012;
  width: fit-content;
  padding: 5px 10px;
  font-size: 14px;
}
.brdr2 {
  border: 1px solid #f5f5f5;
}

.profile_header {
  padding-left: 8%;
}
.bg_profile {
  background-color: #f2f7f7;
  min-height: 100vh;
  padding-top: 8rem;
}
.profile_body {
  margin: 0px auto;
  padding: 0px 8%;
}
.return_card {
  width: 60vw;
}
.profile_store {
  color: gray !important;
  margin-top: -3px;
  font-size: 35px !important;
}
.store_details {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.075), 0px 0px 5px rgba(0, 0, 0, 0.075);
  border: 1px solid rgba(128, 128, 128, 0.164);
  padding: 10px;
  border-radius: 10px;
}

.basic_header {
  border-bottom: 1px solid rgba(128, 128, 128, 0.349);
}
.data_sec {
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #f4f6f8;
  padding: 10px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.detail {
  margin: 10px;
}
.doc_img {
  width: 400px;
  height: 250px;
  object-fit: cover;
}
.log_out_sec {
  padding: 10px;

  margin-top: 10px;
  text-align: right;
}
.log_out_btn {
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 5px;
  background-color: white;
  width: fit-content;
  padding: 10px;
}

.store {
  height: 90vh;
  background-color: #f2f7f7;
}
.header_store {
  height: fit-content;
  width: 100%;
  position: sticky;
  top: 0;
  padding-top: 8rem;
  display: table;
  z-index: 1;
}
.header_sub {
  line-height: 15px;
  padding: 20px 20px 0px 20px;
  width: 60%;
}
.user_data_store {
  line-height: 24px;
}
.piggy_bank {
  background-color: #2f2962;
  border-radius: 5px;
  padding: 5px;
  width: fit-content;
}
.wallet_bank {
  background-color: #2f2962;
  border-radius: 5px;
  padding: 5px;
  width: fit-content;
}
.piggy_img {
  font-size: 28px !important;
}
.store_account_list {
  display: flex;
  margin: 0px auto;
  width: 60%;
  flex-wrap: wrap;
  justify-content: center !important;
}
.store_account_item {
  width: 25vw;
  margin: 10px;
  align-items: center;
  flex-direction: row;
}
.store_account_item:hover {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.096),
    0px 0px 25px rgba(0, 0, 0, 0.068);
}
.store_account_icon {
  width: 40px;
}
.store_link_icon {
  width: 12px;
}
.your_store_link {
  width: 100%;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  margin: 0px 10% 10px 10%;
  border: 1px solid rgba(128, 128, 128, 0.185);
}
.your_store_link:hover {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.096),
    0px 0px 25px rgba(0, 0, 0, 0.068);
}
.link_view_btn {
  width: 120px !important;
  height: fit-content !important;
}
.walletCard {
  width: 60vw;
  /* padding: 10px; */
  margin: 10px;
  background-color: white;
  border: 1px solid rgba(128, 128, 128, 0.247);
  border-radius: 5px;
}
.wallet_card_header {
  width: 100%;
  border-bottom: 1px dashed rgba(128, 128, 128, 0.329);
}
.minus_amount {
  margin: 5px !important;
  padding: 4px;
  border: 1px solid rgb(250, 79, 17);
  border-radius: 5px;
}
.positive_amount {
  margin: 5px !important;
  padding: 4px;
  border: 1px solid rgb(1, 124, 1);
  border-radius: 5px;
}

.wallet_reason {
  background-color: #f0fdf9;
  color: #548e84;
  padding: 10px;
}

.listEnd-msg {
  border-top: 1px solid #9399a4;
  margin-top: 1.5rem;
  padding-top: 0.5rem;
  text-align: center;
  color: #9399a4;
  font-weight: 500;
}

.store-new-tag-container {
  align-items: center;
}

.store-new-tag-container > span {
  top: 0px !important;
  margin-right: 0.5rem;
}
.paymentTriggerMsg{
  position: absolute;
  color: "black";
  font-size:12px;
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.296);
  box-shadow: 10px 10px 10px rgba(128, 128, 128, 0.211);
  padding: 5px 10px;
  margin-bottom: 5px;
  background-color: white;
  z-index: 10;
  width: 150px;
  text-align: center;
}
.arrow_cut{
  transform: rotate(45deg);
  border-right: 1px solid rgba(128, 128, 128, 0.296);
  border-bottom: 1px solid rgba(128, 128, 128, 0.296);
  border-radius: 2px;
  width: 9px;
  height: 9px;
  position: absolute;
  margin-top: 19px;
  background-color: white;
  margin-left: -40px;
  z-index: 2;
}
.target_dot_store{
  width: 10px !important;
  height: 10px !important;
  background-color: #FF9828 !important;
  border: 1px solid white;
  border-radius: 50%;
  position: absolute;
  margin-top: -30px;
  margin-left:30px;
}
.target_dot_past_orders{
  width: 10px !important;
  height: 10px !important;
  background-color: #FF9828 !important;
  border: 1px solid white;
  border-radius: 50%;
  position: absolute;
  margin-top: -15px;
  margin-left: 15%;
}
@media only screen and (max-width: 750px) {
  .profile_body {
    padding: 0px 1%;
  }
  .store_detail {
    padding: 2px;
  }
  .doc_img {
    width: 250px;
  }
  .orders_filter {
    top: 100px;
    padding: 0px 1%;
  }
  .live_menu {
    margin: 0px 5%;
  }
  .store_account_item {
    width: 90vw;
  }
  .store {
    height: 90vh;
  }
  .header_sub {
    width: 100%;
  }
  .your_store_link {
    margin: 20px 0% 10px 0%;
    width: 100vw;
  }
  .walletCard {
    width: 90vw;
  }

  .order_card {
    width: 100%;
  }
  .order_card:nth-child(1) {
    margin-top: 50px;
  }
  .order_meds_edit {
    font-size: 12px;
    margin: 10px 0px;
    width: 100%;
  }
  .continue_btn {
    width: 50%;
  }
  .order_card_header {
    font-size: 12px !important;
  }
  input {
    height: fit-content;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 751px) {
  .orders_filter {
    top: 110px;
  }
  .store_account_list {
    margin-top: 20px;
  }
  .store_account_item {
    width: 50vw;
  }
  .store {
    height: 75vh;
  }
  .order_card:nth-child(1) {
    margin-top: 50px;
  }
  .bg_profile {
    padding-top: 6.5rem;
    padding-bottom: 4rem;
  }
}

@media only screen and (max-width: 600px) {
  .live_menu,
  .go_back {
    font-size: 14px;
  }

  .orders_parent {
    padding-top: 8rem;
  }

  .orders_c2a_cntr {
    flex-direction: column;
  }
  .target_dot_past_orders{
    margin-left: 24%;
  }
}
