.left_top_sec {
  font-size: 14px;
  line-height: 5px;
}
.top_center_sec {
  line-height: 10px;
  padding: 3px 15px;
  margin-top: -5px;
  margin-bottom: 8px;
}
.header_ptr_modal {
  width: 100%;
}
.btn-close {
  font-size: 10px !important;
  margin-top: -50px !important;
  margin-left: 10px !important;
  background-color: white !important;
}
.dialog_cd_sec {
  background-color: #ddf7f5e1;
  color: #17706c;
  width: 100%;
  padding: 10px 20px;
  font-size: 12px;
}
.product_image {
  width: 500px;
  height: 600px;
  background: white;
  padding: 50px;
}
.med_name {
  font-size: 16px;
  line-height: 20px !important;
}
.header_data {
  width: 100% !important;
  margin: 0px;
  padding: 20px 10px;
  color: white;
  font-weight: bold;
  line-height: 5px;
}
.web_display {
  display: block;
}
.modal-content {
  border-radius: 16px !important;
}
.delete_modal_svg {
  width: 120px;
  margin: 10px 20px;
  text-align: center !important;
}
.loader_modal_svg {
  width: 210px;
  margin: -25px 20px 0px 20px;
  text-align: center !important;
}
.inProgressHeader {
  margin: 0px auto !important;
}
.timer_inprogress {
  position: absolute;
  top: 90px;
  left: 127px;
}
.success_modal_svg {
  width: 200px;
  text-align: center !important;
}
.cancel_btn {
  width: 110px;
  margin: 0px 10px;
}
.delete_btn {
  width: 110px;
  margin: 0px 10px;
}
.cancel_btn:hover {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.116), 0px 0px 15px rgba(0, 0, 0, 0.082);
}
.delete_btn:hover {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.116),
    0px 0px 25px rgba(0, 0, 0, 0.082);
}

.modal_content {
  max-width: 250px !important;
  overflow-wrap: break-word !important;
  border: none !important;
}
.try_again {
  border-radius: 30px;
  /* padding: 5px 40px; */
}
.maintance-modal-header {
  padding: 10px 10px 0px 10px !important;
  margin: 0px !important;
}
.returns {
  width: fit-content;
  margin-top: -10px;
  margin-left: 10px;
  padding: 2px 6px;
  font-size: 13px;
  border-radius: 20px;
  background-color: white;
  height: fit-content;
}

.prd_dialog_body {
  background-color: #f2f7f7;
  width: 100%;
  min-height: 310px;
}
.body_inner_div {
  background-color: white;
  margin: 10px 10%;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.096), 0px 0px 15px rgba(0, 0, 0, 0.089);
  border-radius: 10px;
}
.border_bottom {
  border-bottom: 1px solid rgba(128, 128, 128, 0.185);
}
.footer_sec_1 {
  background-color: rgba(165, 165, 165, 0.11);
  padding: 5px 5%;
  margin: 0px 0px;
  width: 100%;
}
.footer_sec_2 {
  padding: 5px 5%;
  margin: 0px 0px;
}
.add_to_cart_footer {
  padding: 10px 0px;
  width: 100%;
  margin-top: 0px !important;
  text-align: center;
  cursor: pointer;
}
.manf_name {
  font-size: 12px;
}
.product-parent-wrapper {
  padding: 5%;
}

.product_parent_div {
  max-width: 95%;
  margin-top: 6rem;
  /* margin-bottom: 2%; */
  padding-right: 0px !important;
  border-radius: 10px;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.089), 0px 0px 50px rgba(0, 0, 0, 0.13);
  display: flex;
}
.disableBtn {
  background-color: rgba(165, 165, 165, 0.37) !important;
  color: rgb(39, 39, 39) !important;
  opacity: 0.9;
}
.product_data {
  width: 900px;
}
.showImage_web {
  display: block;
}
.padding_7 {
  padding: 7px !important;
}
.showImage_mob {
  display: none;
}
.mobile_display {
  display: none !important;
}
.tags {
  margin-top: -25px;
  margin-bottom: 10px;
}
.got_it {
  border-radius: 5px;
  margin-top: 15px;
  margin-left: 10px;
}
.logout {
  border-radius: 5px;
  margin-top: 15px;
  margin-left: 10px;
  width: 150px !important;
}
.go_to_home {
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 15px;
}
.got_it:hover {
  background-color: white !important;
  color: #4b4495 !important;
}
.reasonInput {
  border: 1px solid rgba(128, 128, 128, 0.582);
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  margin: 0px 12px;
}
.remove_btn {
  border: 1px solid rgba(202, 61, 9, 0.753) !important;
  color: rgba(202, 61, 9, 0.753) !important;
  background-color: white;
  border-radius: 5px;
}
.productShimmer_div {
  height: 90vh;
  width: 100%;
}
.logout_footer {
  border: none !important;
}
.timer_tag_product {
  background-color: yellow;
  color: black;
  padding: 4px 4px;
  /* top: -12px;
left: 72%; */
  margin-top: -10.5px;
  font-size: 18px !important;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.productMessage {
  width: 100%;
  padding: 3px;
  margin-bottom: 0px !important;
  font-size: 14px;
  text-align: center;
}
.admin_data {
  padding: 3px 10px 0px 10px !important;
  margin: 0px 10px !important;
}
.retCode {
  font-size: 12px;
  color: #828286a1;
}
.version {
  font-size: 12px;
  color: #828286a1;
}
.modal_footer {
  height: fit-content !important;
  padding: 3px !important;
}
.copy_icon {
  margin-top: -5px !important;
  font-size: 14px !important;
  cursor: pointer;
}
.copy_icon_maint {
  margin-top: -5px !important;
  font-size: 14px !important;
  cursor: pointer;
}
.copy_icon:hover {
  color: #4b4495 !important;
}
.copy_icon_maint:hover {
  color: #47d1cc !important;
}
.modal_dwnlad_csv_btn {
  background-color: #f36d6a !important;
  color: white !important;
  padding: 5px !important;
  border: none !important;
  font-size: 14px !important;
  margin: 0px !important;
}
.codes_message {
  font-size: 12px;
  color: white;
  text-align: center;
  width: 100%;
  margin: 0px auto;
}
.retDataOrderMessageModel {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  color: white !important;
  width: 100%;
  position: relative;
}
.sessionTitle {
  width: 100%;
  text-align: center !important;
  font-size: 22px;
  font-weight: bolder;
  color: #60708b;
}
.sessionSubTitle {
  width: 100%;
  text-align: center !important;
  font-size: 16px;
  color: #8292a5;
  font-weight: 500;
}
.refreshBtn {
  margin-top: 20px;
  border-radius: 5px !important;
  padding: 4px 15px !important;
}

 .order-success-modal.modal-dialog {
  max-width: 100%;
  width: auto !important;
  display: inline-block;
}
.contact_modal_body > img{
  width: 100%;
  height: 100%;
  
}
.contact_modal{
   background-color: rgba(255, 0, 0, 0);
   border: none !important;
   cursor: pointer;
   /* width: fit-content !important; */
}

.cart_generics_modal .modal-content{
  border-radius: 8px !important;
  border: none !important;
  background-color: #FFECEB;
}

.cart_generics_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.cart_generics_header > div:nth-child(1){
  line-height: 20px;
}
.cart_generics_header >div:nth-child(2){
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #FED9D8;
    color: #323232;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cart_generics_comp {
  line-height: 20px;
  background-color: #FED9D8;
  border-radius: 6px;
  padding:3px 6px;
  margin: 0px 10px;
}
.cart_generics_comp > div:nth-child(1){
  color: #F46D6A;
  font-weight: bolder;
}
.cart_generics_list{
  padding: 10px;
  width: 100%;
  overflow: scroll;
  display: flex;
  gap: 10px;
}
.cart_generics_list::-webkit-scrollbar{
  display: none !important;
}
.cart_gen_scroll_click{
  min-width: 25px !important;
  height: 25px !important;
  background-color: rgba(255, 255, 255, 0.221);
  border-radius: 50%;
  display: flex;
  justify-content: center !important;
  align-content: center;
  align-items: center;
  position: sticky;
  right: 5px;
  top: 50%;
  cursor: pointer;
  z-index: 10 !important;

}
.cart_gen_scroll_click:hover{
  background-color: white;
}
.cart_gen_scroll_click_left{
  min-width: 25px !important;
  height: 25px !important;
  background-color: rgba(255, 255, 255, 0.211);
  border-radius: 50%;
  display: flex;
  justify-content: center !important;
  align-content: center;
  align-items: center;
  position: sticky !important;
  margin-left: 0px;
  left: 0px !important;
  top: 50%; 
  cursor: pointer;
  z-index: 10 !important;
}
.cart_gen_scroll_click_left:hover{
  background-color: white;
}
.cart_generics_footer{
  display: flex;
  align-content: center;
  align-items: center;
}
.cart_generics_footer img{
  width: 34px;
  user-select: none;
}
.cart_generics_footer_data{
  padding: 10px;
  width: 100%;
}
.cart_generics_footer_data > div:nth-child(1){
  color: #f36d6a;
}
.cart_gn_spinner{
    color: #F46D6A;
    margin: 5% auto !important;
}
@media only screen and (max-width: 750px) {
  .web_display {
    display: none !important;
  }
  .header_data {
    margin: 0px 10px;
    padding: 5px;

    line-height: 18px;
  }
  .med_name {
    font-size: 12px;
  }
  .manf_name {
    font-size: 10px;
    margin-top: -15px;
  }
  .returns {
    margin-top: 10px;
    font-size: 12px;
    width: fit-content;
  }
  .body_inner_div {
    margin: 10px 2%;
  }
  .showImage_web {
    display: none;
  }
  .showImage_mob {
    display: block;
  }
  .product_parent_div {
    margin: 15% auto;
    padding-left: 0px !important;
    margin-top: 7.5rem;
  }
  .product_image {
    /* width: 37%;
    height:500px; */
    width: 60px;
    height: 60px;
    padding: 2px;
  }
  .mobile_display {
    display: block !important;
  }
  .tags {
    margin-top: -15px;
    margin-bottom: 4px;
  }
  .padding_7 {
    padding: 1px 2px;
    font-size: 10px !important;
  }
  .timer_tag_product {
    margin-top: -8px;
    font-size: 14px;
  }
  .timer_inprogress {
    left: 165px;
  }

  .order-success-modal.modal {
  z-index: 1050;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

/* .order-success-modal.modal-open.modal {
  z-index: 1050;
} */
}

@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .product_parent_div {
    /* flex-direction: column; */
    margin: 20% auto;
  }
  .product_image {
    width: 350px;

    height: 500px;
  }
}
