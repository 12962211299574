.alertCntr {
  background-color: #00000084;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  display: grid;
  place-items: center;
  animation: openDPModal 0.25s forwards ease-in;
}

.alertModal {
  margin: 1rem;
  background-color: white;
  border-radius: 3px;
  min-width: 30%;
  min-height: 200px;
  box-shadow: 0 10px 10px #00000017, 0px 0px 25px #00000017;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.alert_icon {
  position: absolute;
  top: -1.25rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #e8b202;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
}

.alert_icon svg {
  width: 40px;
  height: 40px;
  fill: #e8b202;
  stroke: white;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.alert_header {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.alert_message {
  margin-top: 1.5rem;
  width: 100%;
}

.alert_header p {
  margin: 0;
  line-height: 1.6;
  color: #787878;
  font-size: 0.9rem;
  text-align: center;
}

.alertActions {
  border-top: 1px solid #e5e5e5;
  background-color: #f3f3f3;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.alertActions button {
  margin: 0.5rem 1rem;
  padding: 0.25rem 0.5rem;
  width: 100px;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: white;
  color: #a2a2a2;
  border: 1px solid #a2a2a2;
  border-radius: 3px;
  transition: all 0.25s ease-in;
}

.alertActions button:hover {
  color: white;
  border: 1px solid transparent;
  box-shadow: 0 3px 5px #0000001a, 0px 0px 5px #0000001a;
  transform: scale(1.07);
}

.alert_confirm_btn:hover {
  background-color: #cb9c02;
}

.alert_cancel_btn:hover {
  background-color: #a2a2a2;
}
